import React, { useEffect, useRef } from "react";
import Header from "../components/Header.jsx";
import Demo from "../assets/demo.mp4";
import questions from "../components/faqQuestions.js";
import FAQ from "../components/FAQ.jsx";
import Footer from "../components/Footer.jsx";

const HomePage = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play().catch(error => {
                console.error("Error attempting to play the video:", error);
            });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = document.forms["form"];
        fetch(
            "https://script.google.com/macros/s/AKfycbxi0qp6x-Ci8AB-Du9vSo0v361ujYnnHpgt2paNYObQBAsiGhjk7nnSP-xAvHotE7tXhg/exec",
            { method: "post", body: new FormData(form) }
        );
        alert("You'll be notified");
    };

    return (
        <>
            <Header />
            <div className="w-screen flex justify-center items-center flex-col gap-14">
                <video
                    ref={videoRef}
                    src={Demo}
                    className="md:w-5/12 sm:w-10/12 rounded-2xl"
                    autoPlay
                    muted
                    loop
                ></video>
                <h1 className="md:text-7xl tracking-[0.5em] font-thin">
                    COMING SOON
                </h1>
                <p className="font-bold text-2xl w-5/12 text-center">
                    OUR AIM IS TO PROVIDE{" "}
                    <span className="bg-gradient-to-r from-primary to-indigo-400 inline-block text-transparent bg-clip-text">
                        POWERFUL
                    </span>{" "}
                    AI'S AT AFFORDABLE COSTS
                </p>
                <h2 className="tracking-[0.2em]">ARE YOU READY ?</h2>
                <p className="font-bold text-xl w-5/12 text-center">
                    Due to the variety of tasks WiseForge AI tackles, providing
                    a single accuracy figure isn't practical. However, it
                    consistently delivers results exceeding{" "}
                    <span className="bg-gradient-to-r from-indigo-400 to-primary inline-block text-transparent bg-clip-text">
                        85% accuracy
                    </span>{" "}
                    on many benchmarks
                </p>
                <form
                    name="form"
                    onSubmit={handleSubmit}
                    method="post"
                    action="https://script.google.com/macros/s/AKfycbxi0qp6x-Ci8AB-Du9vSo0v361ujYnnHpgt2paNYObQBAsiGhjk7nnSP-xAvHotE7tXhg/exec"
                    className="input input-bordered input-accent w-full max-w-xs flex justify-between items-center p-0"
                >
                    <input
                        name="email"
                        type="email"
                        placeholder="example@gmail.com"
                        className="w-full mx-5"
                    />
                    <button className="btn btn-accent rounded-none w-2/5 m-0">
                        Notify Me!
                    </button>
                </form>
            </div>
            <h1 className="mt-20 text-3xl ml-[20%] font-bold">FAQ'S</h1>
            <FAQ questions={questions} />
            <Footer />
        </>
    );
};

export default HomePage;
