const questions = [
	{
		question: " Is Vision suitable for all users?",
		answer: "Yes, Vision is designed to cater to a wide range of users, including students, professionals, creatives, and enthusiasts. Whether you're seeking academic assistance, creative inspiration, or practical solutions, Vision is here to help.",
	},
	{
		question: "How does Vision work?",
		answer: "Vision employs sophisticated algorithms to interpret the visual content of uploaded images. It then applies various AI models to generate relevant outputs based on the image's context and user preferences.",
	},
	{
		question: "What is the aim of Mind Forge, the company behind Vision?",
		answer: "At Mind Forge, our vision extends beyond mere innovation, it's about democratizing access to powerful artificial intelligence tools. We are driven by the belief that cutting-edge AI solutions should not be exclusive to a select few but should be accessible and affordable to all. With products like Vision, we aim to make the transformative potential of AI technology available to individuals and organizations alike, empowering them to thrive in an increasingly AI-driven world. By making powerful AI tools affordable and accessible, we are committed to democratizing innovation and fostering a future where everyone can harness the power of AI to achieve their goals.",
	},
	{
		question: "Is Vision the only product offered by Mind Forge?",
		answer: "While Vision is one of our flagship products, Mind Forge is continually exploring new avenues of AI development. While we're proud of Vision's capabilities, we're also committed to expanding our product lineup to address a diverse range of needs and challenges across various industries. Stay tuned for future releases and updates from Mind Forge!",
	},
];

export default questions;
