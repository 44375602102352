import React from "react";

const FAQ = ({ questions }) => {
	const faq = questions.map((item, index) => {
		return (
			<div
				key={index}
				className="collapse collapse-plus bg-base-200 my-5"
			>
				<input
					type="radio"
					name="my-accordion-3"
					defaultChecked={false}
				/>
				<div className="collapse-title text-xl font-medium">
					{item.question}
				</div>
				<div className="collapse-content">
					<p>{item.answer}</p>
				</div>
			</div>
		);
	});
	return <div className="w-7/12 mx-auto mt-10	 mb-14">{faq}</div>;
};

export default FAQ;
