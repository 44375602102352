import React from "react";


const Footer = () => {
	const date = new Date();
	const year = date.getFullYear();

	return (
		<footer className="body-font">
			<div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
				<a
					href="/"
					className="flex title-font font-medium items-center md:justify-start justify-center"
				>
					<span className="ml-3 text-xl">WiseForge</span>
				</a>
				<p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
					© {year.toString()} WiseForge —
					<a
						href="https://x.com/wiseforge"
						className="ml-1"
						rel="noopener noreferrer"
						target="_blank"
						previewlistener="true"
					>
						@wiseforge
					</a>
				</p>
				<span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
					<a
						href="http://x.com/wiseforge"
						className="ml-3 text-gray-500"
					>
						<svg 
							stroke="currentColor"
							class="h-5 w-5"
							stroke-width="2"
							width="15"
								height="15" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">;
							
						
<path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"/>
</svg>
					</a>
					<a
						href="http://instagram.com/wise_forge"
						className="ml-3 text-gray-500"
					>
						<svg
							fill="none"
							stroke="currentColor"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							className="w-5 h-5"
							viewBox="0 0 24 24"
						>
							<rect
								width="20"
								height="20"
								x="2"
								y="2"
								rx="5"
								ry="5"
							></rect>
							<path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
						</svg>
					</a>
					<a
						href="https://www.linkedin.com/in/wiseforge"
						className="ml-3 text-gray-500"
					>
						<svg
							fill="currentColor"
							stroke="currentColor"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="0"
							className="w-5 h-5"
							viewBox="0 0 24 24"
						>
							<path
								stroke="none"
								d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
							></path>
							<circle cx="4" cy="4" r="2" stroke="none"></circle>
						</svg>
					</a>
				</span>
			</div>
		</footer>
	);
};

export default Footer;
