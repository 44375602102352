import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer.jsx";

const Hiring = () => {
 return (
   <>
     <Header hiringPage={true} />
     <div className="w-full flex flex-col justify-center items-center">
       <h1 className="text-3xl font-bold my-24 text-center">
         Join our mission to impact AI's future, your talent is essential
       </h1>
       <div className="flex flex-col md:w-8/12 lg:flex-row px-10 mx-auto">
         <div className="grid flex-grow h-[20rem] card glass rounded-box place-items-center">
           <h1 className="text-3xl font-bold">Front-End Developer</h1>
           <div className="text-2xl font-semibold w-full ml-[12%]">
             <h2 className="">We're looking for &#8594;</h2>
             <p className="w-7/12 text-base font-normal">
               students, beginners, and freshers
             </p>
           </div>
           <div className="text-2xl font-semibold w-full ml-[12%]">
             <h2 className="">Tech Stack Required &#8594;</h2>
             <p className="w-7/12 text-base font-normal">
               React, Node, TailwindCSS, DaisyUI
             </p>
           </div>
           <p className="text-base font-normal">
             Email Your CV to info@wiseforge.in
           </p>
         </div>
         <div className="divider lg:divider-horizontal">OR</div>
         <div className="grid flex-grow h-[20rem] card glass rounded-box place-items-center">
           <h1 className="text-3xl font-bold">Back-End Developer</h1>
           <div className="text-2xl font-semibold w-full ml-[12%]">
             <h2 className="">We're looking for &#8594;</h2>
             <p className="w-7/12 text-base font-normal">
               students, freshers, and AI aspirants
             </p>
           </div>
           <div className="text-2xl font-semibold w-full ml-[12%]">
             <h2 className="">Tech Stack Required &#8594;</h2>
             <p className="w-7/12 text-base font-normal">
               Node, Express, Razorpay, MongoDB, JWT, AI
             </p>
           </div>
           <p className="text-base font-normal">
             Email Your CV to info@wiseforge.in
           </p>
         </div>
       </div>
       <div className="h-[15rem] w-[30rem] rounded-full opacity-75 bg-secondary blur-3xl absolute md:top-1/2 sm:top-3/4 left-[40%] -z-10 -rotate-12"></div>
     </div>
     <Footer />
   </>
 );
};

export default Hiring;
